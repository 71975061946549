<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";
import type { OnApproveData, PayPalButtonsComponent } from "@paypal/paypal-js";

const { pushError } = useNotifications();
const { loadPayPalNamespace } = usePayPal();
const { apiClient } = useShopwareContext();
const { setPaymentMethod, getPaymentMethods, createOrder } = useCheckout();

const gtm = useGtm();
const { lineItemsToTrackingItems } = await useTrackingHelper();

const buttonContainer = ref<HTMLDivElement>();

const payPalButtons = ref<PayPalButtonsComponent | undefined>(undefined);

onMounted(async () => {
  assert(buttonContainer.value !== undefined, "container loaded");

  const paypal = await loadPayPalNamespace();

  const buttons = paypal.Buttons({
    style: {
      shape: "rect",
      label: "buynow",
      layout: "horizontal",
      height: 40,
      tagline: false,
    },
    onError(err) {
      pushError("Bei PayPal express ist etwas fehlgeschlagen");

      console.warn(
        "[components\\checkout\\CheckoutSideCart.vue][onError]",
        err,
      );
    },
    createOrder: async () => {
      const shopwarePaymentMethod = (await getPaymentMethods()).value.find(
        (method) => method.shortName === "pay_pal_payment_handler",
      );

      assert(
        shopwarePaymentMethod !== undefined,
        "PayPal payment method exists",
      );

      await setPaymentMethod(shopwarePaymentMethod);

      const { data: response } = await apiClient.invoke(
        "payPalExpressCreateOrder post /paypal/express/create-order",
      );

      return response?.token;
    },
    onApprove: async (data: OnApproveData) => {
      try {
        await apiClient.invoke(
          "payPalPrepare post /paypal/express/prepare-checkout",
          { body: { token: data.orderID } },
        );

        const order = await createOrder();

        gtm?.push({ ecommerce: null });
        gtm?.push({
          event: "purchase",
          ecommerce: {
            currency: useShopCurrency(),
            value: order.amountTotal || NaN,
            transaction_id: order.orderNumber,
            tax: (order.amountTotal || NaN) - (order.amountNet || NaN),
            shipping: order.shippingTotal || NaN,
            items: await lineItemsToTrackingItems(order.lineItems || []),
          },
        });

        const { data: paymentResponse } = await apiClient.invoke(
          "handlePaymentMethod post /handle-payment",
          {
            body: {
              orderId: order.id,
              errorUrl: `${window?.location?.origin}/checkout/success/${order.id}/unpaid`,
              finishUrl: `${window?.location?.origin}/checkout/success/${order.id}/paid`,
              isPayPalExpressCheckout: true,
              paypalOrderId: data.orderID,
            },
          },
        );

        window.location.href = paymentResponse.redirectUrl;
      } catch (error) {
        console.error(error);
      }
    },
  });

  payPalButtons.value = buttons;

  try {
    await buttons.render(buttonContainer.value);
  } catch (error) {
    // this fails if the modal is closed before the button finishes rendering
    // but nothing needs to be done here
  }
});

onUnmounted(() => {
  payPalButtons.value?.close();
});
</script>

<template>
  <div ref="buttonContainer" class="rd bg-[#ffc439] h-10"></div>
</template>
